
// Training Test data
const trainings = [
    {id:1, img:"images/home/AT.jpg", title: "ATC Training", des:"HECA (Cairo Tower) at Friday, 2nd February 2024 20:00z", alt:"Training"},
    {id:2, img:"images/home/AT.jpg", title: "ATC Training", des:"HEAT (Assiut Tower) at Saturday, 3rd February 2024 20:00z", alt:"Training"},
    {id:3, img:"images/home/PT.jpg", title: "PILOT Training", des:"HEAT (Assiut Tower) at Sunday, 4th February 2024 20:00z", alt:"Training"},
    {id:4, img:"images/home/AT.jpg", title: "ATC Training", des:"HECA (Cairo Tower) at Monday, 5th February 2024 20:00z", alt:"Training"},
];

// Tours Test data
const tours = [
    {id:1, link:"https://tours.th.ivao.aero/index.php?div=EG&tourid=2204", img:"images/tours/ifr24.jpg", title: "Egypt IFR", year: "2024", rmk:"RMK/EG IFR24 TOUR LEGxx", alt:"Egypt-IFR-Tour"},
    {id:2, link:"https://tours.th.ivao.aero/index.php?div=EG&tourid=2209", img:"images/tours/vfr24.jpg", title: "Egypt VFR Tour", year: "2024", rmk:"RMK/EG VFR24 TOUR LEGxx", alt:"Egypt-VFR-Tour"},
    {id:3, link:"https://tours.th.ivao.aero/index.php?div=EG&tourid=2203", img:"images/tours/cargo24.jpg", title: "Egypt Cargo Tour", year: "2024", rmk:"RMK/EG CRG24 TOUR LEGxx", alt:"Egypt-Cargo-Tour"},
    {id:4, link:"https://tours.th.ivao.aero/index.php?div=EG&tourid=2205", img:"images/tours/airline24.jpg", title: "Egyptian Airlines IFR", year: "2024", rmk:"RMK/EGIFR24 TOUR LEGxx", alt:"Egypt-Airline-Tour"},
    {id:5, link:"https://tours.th.ivao.aero/index.php?div=EG&tourid=2207", img:"images/tours/long24.jpg", title: "Egypt Long Haul Tour", year: "2024", rmk:"RMK/EG LH24 TOUR LEGxx", alt:"Egypt-Long-Haul-Tour"},
    {id:6, link:"https://tours.th.ivao.aero/index.php?div=EG&tourid=2210", img:"images/tours/heli24.jpg", title: "Egypt Red Sea Heli Tour", year: "2024", rmk:"RMK/EG HELI24 TOUR LEGxx", alt:"Egypt-Helicopter-Tour"},
];

// EG Events Test data
const egEvents = [
    {id:1, link: "https://tours.th.ivao.aero/index.php?div=EG&tourid=2211", img:"images/events/onlineday24.jpg", title:"18:00z - Egypt Online Day", info:"Every Saturday IVAO Egypt will hold an Online Day Event, which will take place from 18z-21z.. The event will consist of two or more aerodromes or TMAs offering FULL ATC for the duration of the event.. Each Online Day will be in different locations, the locations will be chosen by members through voting through our EG Discord channel or through our EG Facebook page, the locations that receive the most votes will be those that will participate in the event.. Voting will start on Monday, and will end on the Thursday preceding the Saturday of the event.. If you have any questions, contact us via email: eg-ec@ivao.aero", alt:"Event"},
    // {id:2, link: "https://forum.ivao.aero/threads/cairo-rfo.379699/", img:"images/events/cairoRFO.jpg", title:"Cairo RFO", info:"In cooperation with IVAO HQ Events Department, We proudly present Cairo Real Flight Operation (RFO), This mega event aims to simulate real world flights from and to Cairo airport, 229 flights are available for you to book in and out of Cairo Airport (HECA).. During the event, we will be providing full ATC coverage, Bring your wing & enjoy arriving and departing from the city of the great Pyramids.", alt:"Event"},
];

// Events Test data
const ivaoEvents = [
    {id:1, img:"images/events/AT_Online_Day_Winterzeit.jpg", title: "16:00 - [UA] Divisional Online Day", info:"IVAO Ukraine is pleased to announce Online Evenings in Ukraine! We are waiting for all of you every Tuesday from 1600z in Ukrainian airspace! For charts and sceneries refer to our website!", link: "#", alt:"Event"},
    {id:2, img:"images/events/AT_Online_Day_Winterzeit.jpg", title: "18:00 - [TR] Türkiye Online Day", info:"Join us every week on Tuesday to fill the skies and sectors over Türkiye!", link: "#", alt:"Event"},
    {id:3, img:"images/events/AT_Online_Day_Winterzeit.jpg", title: "18:00 - [DE] German Onlineday", info:"Every Tuesday after 7pm Slovenia local time, an online evening of the Slovenian Division takes place on the IVAO network. Air traffic control will be provided as much as possible.", link: "#", alt:"Event"},
    {id:4, img:"images/events/AT_Online_Day_Winterzeit.jpg", title: "16:00 - [AT] Online Day", info:"We are excited to invite you to our weekly online day, which will now take place every Tuesday! Come and enjoy our beatiful landscape and airports!", link: "#", alt:"Event"},
    {id:5, img:"images/events/AT_Online_Day_Winterzeit.jpg", title: "16:00 - [UA] Divisional Online Day", info:"IVAO Ukraine is pleased to announce Online Evenings in Ukraine! We are waiting for all of you every Tuesday from 1600z in Ukrainian airspace airspace airspace airspace! For charts and sceneries refer to our website!", link: "#", alt:"Event"},
    {id:6, img:"images/events/AT_Online_Day_Winterzeit.jpg", title: "16:00 - [UA] Divisional Online Day", info:"IVAO Ukraine is pleased to announce Online Evenings in Ukraine! We are waiting for all of you every Tuesday from 1600z in Ukrainian airspace airspace airspace airspace! For charts and sceneries refer to our website!", link: "#", alt:"Event"},
];

export {trainings, tours, egEvents, ivaoEvents};